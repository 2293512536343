// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-boda-js": () => import("./../../../src/pages/boda.js" /* webpackChunkName: "component---src-pages-boda-js" */),
  "component---src-pages-galeria-js": () => import("./../../../src/pages/galeria.js" /* webpackChunkName: "component---src-pages-galeria-js" */),
  "component---src-pages-hoteles-js": () => import("./../../../src/pages/hoteles.js" /* webpackChunkName: "component---src-pages-hoteles-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-informacion-js": () => import("./../../../src/pages/informacion.js" /* webpackChunkName: "component---src-pages-informacion-js" */),
  "component---src-pages-menu-js": () => import("./../../../src/pages/menu.js" /* webpackChunkName: "component---src-pages-menu-js" */),
  "component---src-pages-nuestra-gente-js": () => import("./../../../src/pages/nuestra-gente.js" /* webpackChunkName: "component---src-pages-nuestra-gente-js" */),
  "component---src-pages-nuestra-historia-js": () => import("./../../../src/pages/nuestra-historia.js" /* webpackChunkName: "component---src-pages-nuestra-historia-js" */),
  "component---src-pages-paradas-autobus-js": () => import("./../../../src/pages/paradas-autobus.js" /* webpackChunkName: "component---src-pages-paradas-autobus-js" */),
  "component---src-pages-peluquerias-js": () => import("./../../../src/pages/peluquerias.js" /* webpackChunkName: "component---src-pages-peluquerias-js" */),
  "component---src-pages-puntos-de-interes-js": () => import("./../../../src/pages/puntos-de-interes.js" /* webpackChunkName: "component---src-pages-puntos-de-interes-js" */),
  "component---src-pages-regalos-js": () => import("./../../../src/pages/regalos.js" /* webpackChunkName: "component---src-pages-regalos-js" */),
  "component---src-pages-restaurantes-js": () => import("./../../../src/pages/restaurantes.js" /* webpackChunkName: "component---src-pages-restaurantes-js" */),
  "component---src-pages-rsvp-js": () => import("./../../../src/pages/rsvp.js" /* webpackChunkName: "component---src-pages-rsvp-js" */),
  "component---src-pages-wedding-planner-js": () => import("./../../../src/pages/wedding-planner.js" /* webpackChunkName: "component---src-pages-wedding-planner-js" */)
}

